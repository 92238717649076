<template>
    <div class="d-flex" style="background-color: #105E30; position: fixed; width: 100%; z-index: 3;">
        <div @click="navigate('')" style="background-color: #105E30; display:flex; cursor: pointer;">
            <v-img style="margin: auto" class="logo" :src="require('../assets/logo.svg')"/>
        </div>
        <v-spacer/>
        <div class="nav">
            <v-btn :color="url == 'home' ? '#ddd':'#F5F5F5'" tile @click="navigate('')" elevation="0" height="100" style="width: 150px">HOME</v-btn>
            <v-btn :color="url == 'information' ? '#ddd':'#F5F5F5'" tile @click="navigate('information')" elevation="0" height="100" style="width: 150px">Attractions</v-btn>
            <v-btn :color="url == 'facilities' ? '#ddd':'#F5F5F5'" tile @click="navigate('facilities')" elevation="0" height="100" style="width: 150px">FACILITIES</v-btn>
            <a :href="date" target="_blank">
                <v-btn elevation="0" height="100" style="width: 150px; color: white" color="#263238">BOOK ONLINE</v-btn>
            </a>
        </div>
        <div class="icon">
            <v-btn tile @click="toggleDrawer" elevation="0" height="100" style="width: 120px">
                <v-icon>mdi-menu</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";

export default {
    mounted () {
        this.getDate();
        this.initNavigate();
    },
    computed: {
        ...mapGetters(['url']),
    },
    data: () => ({
        date: '',
        home: false,
        information: false,
        facilities: false,
        contact: false
    }),
    methods: {
        ...mapMutations(['toggleDrawer', 'navigator']),
        getDate() {
            let d = moment().format('L'); 

            let day = d.split('/')[1];
            let month = d.split('/')[0];
            let year = d.split('/')[2];

            this.date = `https://secure.staah.com/common-cgi/properties/b2c/Booknow.pl?action=showpage&MotelId=2474&checkIn=${year}-${month}-${day}&unk=1292`;
        },
        navigate(path) {
            const url = window.location.pathname.split('/');
            if (url[1] !== path) {
                this.$router.push(`/${path}`);
            }
            

            if (path == '') {
                this.navigator('home');
            } else {
                this.navigator(path);
            }
        },
        initNavigate() {
            const url = window.location.pathname.split('/');
            if (url[1] == '') {
                this.navigator('home');
            } else {
                this.navigator(url[1]);
            }
        }
    }
}
</script>

<style scoped>
@media only screen and (min-width: 1199px) {
    .logo {
        width: 150px;
        height: 50px;
    }

    .nav {
        display: flex;
    }

    .icon {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    .logo {
        width: 100px;
        height: 50px;
    }

    .nav {
        display: none;
    }

    .icon {
        display: block;
    }
}
</style>
