export default {
  state: {
    prompt: false,
    drawer: false,
    title: "",
    subtitle: "",
    slides: [],
    url: "",
    contact: false,
  },
  mutations: {
    setContact(state, bol) {
      state.contact = bol;
    },
    openPrompt(state) {
      state.prompt = true;
    },
    closePrompt(state) {
      state.prompt = false;
    },
    setPrompt(state, payload) {
      state.prompt = payload;
    },
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    navigator(state, url) {
      state.url = url;
    },
    setPromptInfo(state, payload) {
      state.title = payload.title;
      state.subtitle = payload.subtitle;
      state.slides = payload.slides;
    },
  },
  actions: {},
  getters: {
    prompt(state) {
      return state.prompt;
    },
    drawer(state) {
      return state.drawer;
    },
    title(state) {
      return state.title;
    },
    subtitle(state) {
      return state.subtitle;
    },
    slides(state) {
      return state.slides;
    },
    url(state) {
      return state.url;
    },
    contact(state) {
      return state.contact;
    },
  },
};
