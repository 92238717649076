/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCarousel = /* GraphQL */ `
  query GetCarousel {
    getCarousel {
      main
      images
    }
  }
`;
export const listObjects = /* GraphQL */ `
  query ListObjects {
    listObjects {
      nextToken
      items {
        index
        title
        subtitle
        description
        main
        images
      }
    }
  }
`;
export const getObject = /* GraphQL */ `
  query GetObject($index: String) {
    getObject(index: $index) {
      index
      title
      subtitle
      description
      main
      images
    }
  }
`;
export const getFacilities = /* GraphQL */ `
  query GetFacilities {
    getFacilities {
      description
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  query DeleteImage($input: ImageInput) {
    deleteImage(input: $input)
  }
`;
