/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteObject = /* GraphQL */ `
  mutation DeleteObject($index: String) {
    deleteObject(index: $index) {
      index
      title
      subtitle
      description
      main
      images
    }
  }
`;
export const updateCarousel = /* GraphQL */ `
  mutation UpdateCarousel($input: CarouselInput) {
    updateCarousel(input: $input) {
      main
      images
    }
  }
`;
export const updateObject = /* GraphQL */ `
  mutation UpdateObject($input: ObjectInput) {
    updateObject(input: $input) {
      index
      title
      subtitle
      description
      main
      images
    }
  }
`;
export const updateFacilities = /* GraphQL */ `
  mutation UpdateFacilities($input: FacilitiesInput) {
    updateFacilities(input: $input) {
      description
    }
  }
`;
export const addObject = /* GraphQL */ `
  mutation AddObject($input: ObjectInput) {
    addObject(input: $input) {
      index
      title
      subtitle
      description
      main
      images
    }
  }
`;
export const saveImage = /* GraphQL */ `
  mutation SaveImage($input: ImageInput) {
    saveImage(input: $input)
  }
`;
